/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-10-14",
    versionChannel: "nightly",
    buildDate: "2024-10-14T00:06:22.246Z",
    commitHash: "3c5297c5a9259f31b1cbb2d0e8509da7fedd205c",
};
